import React from 'react';
import './Contact.css';

const Contact = () => {
    return (
        <section className='contact' id='contact'>
            <h2>Contact me!</h2>
            <p>Send a carrier pigeon, or use one of the following methods:</p>
            <ul className='contact-list'>
                <li>
                    <a href='mailto:shawn.griffith0@gmail.com'>email</a>
                </li>
                <li>
                    <a
                        href='https://www.linkedin.com/in/shawn-griffith-1761b1a2/'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        linkedin
                    </a>
                </li>
                <li>
                    <a href='https://github.com/satyr23' target='_blank' rel='noopener noreferrer'>
                        github
                    </a>
                </li>
            </ul>
        </section>
    );
};

export default Contact;
